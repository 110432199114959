const Images = [
    { image: '../Assets/slider/AF1QipPkbfaxB653cgIUxNg8PfrJ240SOx8yZQj_8cK2=s812-k-no.jpg' },
    { image: '../Assets/slider/AF1QipPVyxpe9eQ4rTVt15mbkG7HCZa344CGQw22XXSC=s1536.jpg' },
    { image: '../Assets/slider/456156B8-2C25-43D7-9C24-BA7AA0DA9399.JPG' },
    { image: '../Assets/slider/C9101443-2C52-46F3-B948-3FBF81AE9FB8.JPG' },
    { image: '../Assets/slider/DEB32AB4-0572-4E7F-9085-CEFB1F111408.JPG' },
    { image: '../Assets/slider/IMG_3193.jpg' },
    { image: '../Assets/slider/IMG_3456.jpg' },
    { image: '../Assets/slider/IMG_3513.jpg' },
    { image: '../Assets/slider/IMG_3627.jpg' },
    { image: '../Assets/slider/IMG_3790.jpg' },
    { image: '../Assets/slider/IMG_4041.jpg' },
    { image: '../Assets/slider/IMG_4164.jpg' },
    { image: '../Assets/slider/IMG_4385.jpg' },
    { image: '../Assets/slider/IMG_4457.jpg' },
]

export { Images }