import React, { useState } from 'react';
import '../Styling/Scheduling.css';
import emailjs from 'emailjs-com';

function Scheduling() {
  function HandleSubmit(e) {
    e.preventDefault();

    emailjs.sendForm('service_vcbfct7', 'template_vmekudj', e.target, 'user_f4xPUQg78hKutxQ3LLYaS')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset()

    setSuccess(!success)
    console.log("NEW SUBMISSION!!")

}

const [ success, setSuccess ] = useState(false);

const submit = success => {
    setSuccess(!success);
};
    
  return (
    <div className='scheduling'>
      <div className='hero__title__Container'>
        <h2 className='heroTitle'>REQUEST<br/>A QUOTE</h2>
      </div>
      <div className='form__container'>
      {success == false && (
        <form className='form__wrapper' onSubmit={HandleSubmit}>
            <span className='row__item'>
                <label>*FULL NAME</label>
                <input type='text' required name='fName' />
            </span>
            <span className='row__item'>
                <label>*PHONE</label>
                <input type='text'required name='phone'/>
            </span>
            <span className='row__item'>
                <label>*SERVICE</label>
                <input type='text' required name='service' />
            </span>
            <span className='row__item'>
                <label>*WHEN WOULD YOU LIKE TO DROP OFF YOUR PARTS</label>
                <input type='text' required name='when' />
            </span>
            <span className='row__item'>
                <label>LEAVE A MESSAGE</label>
                <textarea name='moreInfo' />
            </span>
            <button className='form__button'>SUBMIT</button>
        </form>
        )}

        {success == true && (
          <span className='submitSuccess__wrapper'>
            <h1 className='submitSuccess_title'>Thanks for your submission</h1>
            <p className='email__link'>Get in touch</p>
            <a href='mailto:Arizonacerakote@gmail.com' className='email__link'>Arizonacerakote@gmail.com</a> 
          </span>
        )}
      </div>

    </div>
  )
}

export default Scheduling