import React, { useState } from 'react';
import '../Styling/Navbar.css';

export default function Navbar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

  return (
    <div className='navbar'>
        <nav className="navbar__menu" id={click ? 'menuClicked':''}>
            <div className='navbar__menu__top'>
              {/* Header Logo */}
                <a href='/'>
                    <img className='navbar__logo' src='../Assets/logo_responsive.png' alt='' />
                </a>

                <button onClick = {handleClick} className='navbar__icon'>
                    <img src='../Assets/menu__icon.png' alt='' id= {click ? 'hideIcon':'displayIcon'} className='nav__hamburger'/>
                    <img src='../Assets/menu__close.png' alt='' id= {click ? 'displayIcon':'hideIcon'} className='nav__hamburger'/>
                </button>
            </div>
            
            <div>
                <div className={click ? 'nav__menu__active':'nav__menu__inactive'}>
                    <div className='navlinks__wrapper'>
                        <a href='/' className='circle__slctr addit__circle'>
                            <p>HOME</p>
                        </a>
                        <a href='/Schedule-an-appointment' className='circle__slctr cntr__cir'>
                            <p>REQUEST<br/>A QUOTE</p>
                        </a>
                        <a href='/FAQs' className='circle__slctr rgt__cir'>
                            <p>FAQ</p>
                        </a>
                    </div>
                </div>
            </div>
            
        </nav>
    </div>
    )
}
