import React, { useRef, useEffect, useState } from 'react';
import '../Styling/Home.css';
import { motion } from 'framer-motion';
import { Images } from '../Assets/CarrouselAssets.js';

function Home() {
  const [width, setWidth] = useState(0);
  const carousel = useRef();

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
  },[]);

  return (
    <div className='home'>
      <div className='hero__title__Container'>
        <img className='hero__titleImg' src='./Assets/home__hero__title.png' alt='header' />
      </div>

      <div className='Hero__wrapper'>
        <span className='hero__text'>
          <p>We are BDL Certified, specializing in ceramic exhaust coatings. Whether your project is brand new needing protection, or used needing a facelift, Cerakote is a fantastic, durable option. <br/>We provide a quality Cerakote finish that will last the test of time. If at anytime you have issues with your coating, don’t hesitate to contact us. </p>
          <span>
            <a href=''><img className='hero__icon' src='../Assets/instagram-icon.png' alt='' /></a>
            <a href=''><img className='hero__icon' src='../Assets/facebook-icon.png' alt='' /></a>
          </span>
          <a href='/Schedule-an-appointment'>
            <button className='hero__button'>REQUEST A QUOTE</button>
          </a>
        </span>

        <img className='home__hero__img' src='./Assets/home__hero.png' alt='primary image'/>
      </div>

      <div className='reviews__container'>
        <h3 className='reviews__title'>What our customers<br />are saying:</h3>
        <span className='review__item'>
          <img src='../Assets/spray__reviewIcon.png' alt='' />
          <p>“Had a great experience with them! High quality craftsmanship with great turnaround time!"</p>
        </span>
        <span className='review__item'>
          <img src='../Assets/shiny__reviewIcon.png' alt='' />
          <p>“Attention to detail, compettive pricing, and quick turnaround. Will get more of my business and would reccomend to anyone!”</p>
        </span>
        <span className='review__item'>
          <img src='../Assets/exhaust__reviewIcon.png' alt='' />
          <p>“Amazing Service. [They] got my parts done super quick and they look amazing!”</p>
        </span>
      </div>

      {/* scrolling images */}
      <div className='carousel__wrapper'>
        <motion.div ref={carousel} className="carousel__container" whileTap={{ cursor: "grabbing" }}  >
          <motion.div drag="x" dragConstraints={{ right: 0, left: - width }} className="carousel" >
          {Images.map((item) => {
              return (
              <motion.div className="item">
                  <img src={item.image} alt='' />
              </motion.div>
              );
          })}
          </motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default Home