import React from 'react';
import '../Styling/FAQ.css';

function FAQ() {
  return (
    <div className='faq'>
      <div className='hero__title__Container'>
        <h2 className='heroTitle'>FAQs</h2>
      </div>

      <div className='question__container'>
        <div className='question__wrapper'>
        <h3>WHAT IS CERAKOTE?</h3>
        <p>The unique formulation used for Cerakote ceramic coating enhances a number of physical performance properties including abrasion/wear resistance, corrosion resistance, chemical resistance, impact strength, and hardness. </p>
        <p>Each of these properties is rigorously tested to guarantee that Cerakote products remain at the forefront of the ceramic coatings market. Cerakote ceramic coatings utilize state-of-the-art technology to out-perform any competitive coating in both laboratory settings and real world applications.</p>
        
        <hr className='faqLine' />
        
        <h3>What can be coated?</h3>
        <p>Cerakote is a specialized coating that can be applied to wood, polymers, metals, and plastics that is composed of a polymer-ceramic compound.</p>
        
        <hr className='faqLine' />

        <h3 className='smallTitle'>What are the advantages to Cerakote vs another coating?</h3>
        <a href='https://www.cerakote.com/resources/testing' target="_blank" rel="noopener noreferrer"><p>LEARN MORE...</p></a>
        <h3 className='smallTitle'>What colors are available?</h3>
        <a href='https://www.cerakote.com/shop/cerakote-coating' target="_blank" rel="noopener noreferrer"><p>LEARN MORE...</p></a>
        </div>
      </div>

      <div className='video__container'>
        {/* video 1 */}
        <iframe width="560" height="315" src="https://www.youtube.com/embed/x4iD7obxEqg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        {/* video 2 */}
        <iframe width="560" height="315" src="https://www.youtube.com/embed/YlUwOR4Tq10" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div className='video__container'>
        {/* video 1 */}
        <iframe width="560" height="315" src="https://www.youtube.com/embed/K9CreQvj8_Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        {/* video 2 */}
        <iframe width="560" height="315" src="https://www.youtube.com/embed/IL_bXx_qtfo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  )
}

export default FAQ