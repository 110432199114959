import './App.css';
import { Routes, Route } from "react-router-dom";
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';
import Home from './Pages/Home.js';
import FAQ from './Pages/FAQ.js';
import Scheduling from './Pages/Scheduling.js';

function App() {
  return (
    <div className="App">
      <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/FAQs" element={<FAQ />} />
          <Route path="/Schedule-an-appointment" element={<Scheduling />} />
        </Routes>
      <Footer />
    </div>
  );
}

export default App;
