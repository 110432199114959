import React from 'react';
import '../Styling/Footer.css';

function Footer() {
    return (
        <div className='footer'>
            <hr className='line' />
            <div className='text__scroll__wrapper'>
                <p className='text__scroll'>CERAMIC EXHAUST COATING + GUN COATING + MUCH MORE</p>
            </div>
            {/* Footer Items */}
            <div className='circle__slctr__wrapper'>
                <a href='/' className='circle__slctr lft__cir'>
                    <p>HOME</p>
                </a>
                <a href='/Schedule-an-appointment' className='circle__slctr cntr__cir'>
                    <p>REQUEST<br/>A QUOTE</p>
                </a>
                <a href='/FAQs' className='circle__slctr rgt__cir'>
                    <p>FAQ</p>
                </a>
            </div>

            <hr className='line' />
            <span className='footer__text'>
                <p>&#169;ARIZONA CERAKOTE - ALL RIGHTS RESERVED</p>
                <p>Proudly built by WWW.AFWEB.DEV</p>
            </span>
        </div>
    )
}

export default Footer